import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

const LoginPage = lazy(() => import("../pages/login/login.page"));
const MainPage = lazy(() => import("../pages/main/main.page"));
const NewsListPage = lazy(() => import("../pages/news/news.page"));
const ContactListPage = lazy(() => import("../pages/contact/contact.page"));
const UserListPage = lazy(() => import("../pages/user/user.page"));
const CategoryListPage = lazy(() => import("../pages/category/category.page"));
const CreateNewsPage = lazy(() => import("../pages/createNews/createNews.page"));

const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/news" />,
  },
  {
    path: "/auth/login",
    component: LoginPage,
  },
  {
    route: "*",
    component: MainPage,
    routes: [
      {
        path: "/contact",
        exact: true,
        component: ContactListPage,
      },
      {
        path: "/user",
        exact: true,
        component: UserListPage,
      },
      {
        path: "/news",
        exact: true,
        component: NewsListPage,
      },
      {
        path: "/category",
        exact: true,
        component: CategoryListPage,
      },
      {
        path: "/news/create",
        exact: true,
        component: CreateNewsPage,
      },
      {
        path: "/news/:newsId/edit",
        exact: true,
        component: CreateNewsPage,
      },
    ],
  },
];

export default routes;

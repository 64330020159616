import React, { Suspense } from "react";
import logo from "./logo.svg";
import "./app.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { ProgressBar } from "react-bootstrap";
import routes from "./routers/routes";
import { renderRoutes } from "react-router-config";
import { BrowserRouter, Switch } from "react-router-dom";
import AppContextProvider from "./contexts/app.context";
import { ToastContainer, toast } from "react-toastify";

function App() {
  const { t, i18n } = useTranslation();
  return (
    <Suspense fallback={<ProgressBar animated now={100} />}>
      <AppContextProvider>
        <BrowserRouter>{renderRoutes(routes)}</BrowserRouter>
        <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
      </AppContextProvider>
    </Suspense>
  );
}

export default App;

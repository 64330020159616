import constant from "../constants/constant";

export const appReducer = (state, action) => {
  switch (action.type) {
    case constant.SET_JWT_TOKEN_ACTION:
      return {
        ...state,
        jwtToken: action.jwtToken
      }
    case constant.SET_LOGIN_USER_ACTION:
        return {
            ...state,
            loginUser: action.loginUser
          }
    default:
      return state;
  }
};

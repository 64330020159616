export default {
  APP_STATE_KEY: "APP_STATE_KEY",
  SET_JWT_TOKEN_ACTION: "SET_JWT_TOKEN_ACTION",
  SET_LOGIN_USER_ACTION: "SET_LOGIN_USER_ACTION",
  DEFAULT_PAGE_SIZE: 5,
  DATE_TIME_FORMAT: "DD MMMM, YYYY",
  DATE_TIME_EXPIRED_FORMAT: "DD/MMMM/YYYY HH:mm:ss",
  ROLE_ADMIN: "ADMIN",
  ROLE_ADMIN_NEWS: "ROLE_ADMIN_NEWS",
  ROLE_ADMIN_RECRUITMENT: "ROLE_ADMIN_RECRUITMENT",
  ROLE_ROOT: "ROLE_ROOT",
  ROLE_MODERATOR: "MODERATOR",
};
